const englishTranslation = {
  home: 'Home',
  user_management: 'User Management',
  user: 'User',
  brand: 'Brand',
  role: 'Role',
  measures: 'Measures',
  claim_management: 'Claim Management',
  order_engine: 'Order Engine',
  product_engine: 'Product Engine',
  create_new_product: 'Create new product',
  supplier_map: 'Supplier Map',
  compliance: 'Compliance',
  over_view: 'Overview',
  product_compliance: 'Product Compliance',
  reports: 'Reports',
  reporting: 'Reporting',
  product_lca: 'Product LCA',
  clustring: 'Clustring',
  impact_label: 'Impact Label',
  eCom_widget: 'E-com Widget',
  qr_codes: 'QR Code',
  analytics: 'Analytics',
  settings: 'Settings',
  back: 'Back',
  demo_account: 'Demo Account',
  your_product: 'Your Product',
  retailer: 'Retailer',
  status: 'Status',
  approved: 'Approved',
  approve: 'Approve',
  submit_for_review: 'Submit for review',
  submitted_for_review: 'Submitted for review',
  verified: 'Verified',
  pending: 'Pending',
  percentile: 'percentile',
  renoon_score: 'Renoon Score',
  brand_name: 'Brand Name',
  contract_start_date: 'Contract Start Date',
  contract_end_date: 'Contract End Date',
  max_products: 'Max Products',
  max_users: 'Max Users',
  features: 'Features',
  contract_files: 'Contract file',
  logo: 'Logo',
  actions: 'Actions',
  add_brand: 'Add Brand',
  brands: 'BRANDS',
  page: 'Page',
  download: 'Download',
  update_brand: 'UPDATE BRAND',
  brand_onboarding_form: 'BRAND ONBOARDING FORM',
  update: 'Update',
  submit: 'Submit',
  cancel: 'Cancel',
  delete: 'Delete',
  duplicate: 'Duplicate',
  delete_brand: 'Delete Brand',
  are_you_sure_you_want_to_delete_this_brand: 'Are you sure you want to delete this brand ?',
  are_you_sure_you_want_to_delete_this_user: 'Are you sure you want to delete this user ?',
  brand_name_required: 'Brand Name is required',
  contract_start_date_required: 'Contract start tate is required',
  contract_end_date_required: 'Contract End Date is required',
  max_product_required: 'Max Products is required',
  max_user_required: 'Max Users is required',
  features_required: 'Features are required',
  contract_files_required: 'Contract file is required',
  logo_required: 'Logo is required',
  add_role: 'Add Role',
  role_name: 'Role Name',
  resources: 'Resources',
  loading: 'Loading',
  roles: 'ROLES',
  edit: 'Edit',
  update_role: 'Update Role',
  create_role: 'Create Role',
  role_form: 'Create Role',
  add_users: 'Add Users',
  update_user: 'Update User',
  user_onboarding_form: 'User Onboarding Form',
  first_name: 'First Name',
  last_name: 'Last Name',
  email_address: 'Email Address',
  password: 'Password',
  select_role: 'Select Role',
  select_brand: 'Select Brand',
  create_user_account: 'Create User Account',
  users: 'USERS',
  email: 'E-mail',
  begin: 'Begin',
  manage: 'Manage',
  goals_targets: 'Goals and targets',
  your_ongoing_jurney_as_a_transparent_and_commited_companies_continues_here: 'Your ongoing commitment to transparency continues here',
  goals_sets: 'Goal Sets',
  certifications_and_proof_points: 'Certifications and proof points',
  select_category: 'Select claims',
  suggested_category: 'Suggested claims',
  discovery_mode: 'Discovery mode',
  questions_answered: 'Questions answered',
  select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically:
    'Select the values you want to claim to easily aggregate your data in the section below - updates automatically.',
  view_and_reply_to_as_many_questions_as_you_can_to_discover_which_values_you_could_claim_based_on_your_data_and_documentation_of_prooof:
    'View & reply to as many questions as you can to discover which values you could claim based on your data and documentation of proof.',
  next: 'Next',
  prev: 'Prev',
  uploaded: 'Uploaded',
  you_have_set_this_as_a_goal: "You've set this as a goal!",
  set_as_future_goal: 'Set as future goal',
  choose_file: 'Choose file',
  file_types: 'pdf, doc, docx, csv, txt, xls, xlsx, ppt, pptx, png, jpg, jpeg, mp4, mov, webm',
  extra_notes: 'Describe more',
  pick_from_library: 'Pick From Library',
  create_new: 'Create New',
  certificate_details: 'Certification details',
  attachment: 'Attachment',
  insert_name: 'Insert name',
  select_type: 'Select type',
  emitted_by: 'Emitted by',
  insert_date_of_emission: 'Insert date of emission',
  insert_expiration_date: 'Insert expiration date',
  select_certificate_owner: 'Select document owner',
  create: 'Create',
  attachment_is_required: 'Attachment is required',
  name_is_required: 'Name is required',
  document_type_is_required: 'Document type is required',
  emitted_by_is_required: 'Emitted by is required',
  emission_date_is_required: 'Emission date is required',
  expiration_date_is_required: 'Expiration date is required',
  certificate_owner_is_required: 'Certificate owner is required',
  pick_certificate: 'Pick document',
  preview: 'Preview',
  name: 'Name',
  date_of_emission: 'Date of emission',
  expiration_date: 'Expiration date',
  alerts: 'Alerts',
  thirty_days_before_expiration_date: '30 days before expiration date',
  validated: 'Valid',
  form_issuing_today: 'Document validity',
  certifications_and_proof_points_uploaded: 'Certifications and proof points uploaded',
  verified_date: 'Validation date',
  privacy: 'Privacy',
  search: 'Search',
  view_profile: 'View Profile',
  user_name: 'User name',
  user_profile: 'User Profile',
  log_out: 'Log Out',
  user_details: 'User Details',
  full_name: 'Full name',
  form_status: 'Form status',
  visibility: 'Visibility',
  image: 'Image',
  E_commerce_item_code: 'E-commerce Item Code',
  title: 'Title',
  supply_chain: 'Supply Chain',
  collection: 'Collection',
  uploaded_products: 'Uploaded Products',
  upload_product: 'Upload Products',
  new_product: 'New Product',
  additional_product_facts_form: 'Additional product facts form',
  total: 'Total',
  product_title: 'Product title',
  enter_sku: 'Enter SKU',
  e_commerce_id: 'E-commerce Id',
  item_code: 'Item Code',
  insert_collection: 'Insert Collection',
  composition: 'Composition',
  care_instruction: 'Care instructions',
  additional_care_info: 'Additional care info',
  weight: 'Weight',
  colors: 'Colors',
  choose_category: 'Choose claim',
  price: 'Price',
  save: 'Save',
  enter_detail_here: 'Enter detail here',
  enter_collection_name: 'Enter collection name',
  mandatory_for_product_creation: 'Mandatory for widget installation',
  mandatory_for_qr_generation: 'Mandatory for QR generation',
  attach_image: 'Attach Image',
  upload_files: 'Upload files',
  upload_CSV_file: 'Upload CSV file',
  upload_images_zip: 'Upload Images or zip file',
  update_product: 'Update Product',
  material: 'Material',
  percentage: 'Percentage',
  add: 'Add',
  qr_id: 'QR Id',
  mandatory_ecommerceId: 'Mandatory e-commerce Id',
  weave: 'Weave',
  title_is_required: 'Title is required',
  weave_is_required: 'Weave is required',
  sku_id_is_required: 'Either SKU id or E-commerce id is required.',
  ecommerce_id_is_required: 'Either SKU id or E-commerce id is required.',
  cannot_contain_slash_or_underscore: 'Cannot contain / or _',
  no_files_selected: 'No file selected',
  invalid_file_format: 'Invalid file format (support only .jpeg.png)',
  file_size_exceeds_limit: 'File size exceeds the limit of 1mb',
  total_file_size_exceeds_limit: 'Total file size exceeds the limit of 5mb',
  active: 'Active',
  archive: 'Archive',
  download_QR: 'Download QR',
  no_product_found: 'No product found',
  these_are_the_claim_which_are_approved_pending_and_rejected_please_take_the_action_as_requested: 'These are the claim approved, pending and rejected. Please take the action as requested.',
  requested_category: 'Requested claims',
  submit_the_detail_and_wait_for_approval: 'Submit the detail and wait for approval',
  submitted: 'Submitted',
  set_of_questions_to_be_answered: 'Set of questions to be answered',
  verify: 'Verify',
  categories_to_be_reviewed: 'Claims to be reviewed',
  are_you_sure_you_want_to_delete_this_certificate: 'Are you sure you want to delete this document?',
  delete_certificate: 'Delete document',
  select_the_value_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below: 'Select the value you want to claim to easily aggregate your data in the section below',
  review_categories: 'Review Claims',
  review: 'Review',
  categories: 'Claims',
  categories_and_claims: 'Claims',
  note_before_you_start_you_can_save_and_edit_your_questions_anytime_before_submitting: 'Note before you start: you can save and edit your questions anytime before submitting.',
  enjoy_the_process: 'Enjoy the process.',
  eco_packaging: 'Eco packaging',
  submit_the_detail: 'Submit the detail',
  claim_status: 'Claim Status',
  create_the_claim: 'Create the claim',
  save_claim: 'Save claim',
  reject_document: 'Reject Document',
  reject: 'Reject',
  reason_for_rejection: 'Reason for rejection',
  locally_made: 'Locally Made',
  reviewd_category: 'Reviewed claims',
  document_already_uploaded: 'Document already uploaded',
  select_or_enter_a_claim: 'Select or enter a claim',
  reason: 'Reason',
  explain_for_selecting_the_particular_status_for_the_claim: 'Explain for selecting the particular status for the claim',
  assign_a_label: 'Assign a label',
  label_name: 'Label name',
  label_eg: 'Eg., 2024 Collection',
  description: 'Description',
  assign_document: 'Assign document',
  document_attach: 'Document attach',
  assigned_document: 'Assigned document',
  label_assigned: 'Label assigned',
  all: 'All',
  supply_chain_management: 'Supply Chain Management',
  supplier: 'Supplier',
  suppliers: 'Suppliers',
  upload_csv: 'Upload CSV',
  add_suppliers: 'Add Suppliers',
  view_claims: 'View Claims',
  add_proudcts: 'Add goods or service',
  create_supply_chain: 'Create supply chain',
  suppliers_have_products_or_services: 'Suppliers provide goods or services',
  claim_name: 'Claim name',
  claims: 'Claims',
  list_of_claims: 'List of Claims',
  add_supplier: 'Add Supplier',
  name_eg: 'Eg.: Renoon',
  vanity_name: 'Supplier vanity name',
  products: 'Connected products',
  website_url: 'Website URL',
  website_eg: 'Eg.: https://renoon.com',
  vat_eg: 'Eg.: IT123456789',
  vat_code: 'VAT Code',
  vat: 'VAT',
  address: 'Address',
  state: 'State',
  city: 'City',
  region_country: 'Country/Region',
  street: 'Street',
  upload_images: 'Upload Images',
  contact: 'Contact',
  no_of_employee: 'No of employee',
  no_of_factories: 'No of factories',
  add_a_new_supplier_by_submitting_this_details: 'Add a new supplier by submitting this details',
  add_new_products: 'Add supplier product',
  document: 'Document',
  select_products: 'Select Products',
  more_custom_properties_may_be_added_like_colors_etc: 'More custom properties may be added like colors etc.',
  they_are_not_always_relevent_and_not_needed_for_the_mvp: 'They are not always relevent and not needed for the MVP.',
  vanity_name_is_required: 'Vanity name is required',
  website_url_is_required: 'Website URL is required',
  vat_is_required: 'Vat is required',
  description_is_required: 'Description is required',
  contact_name_is_required: 'Contact name is required',
  contact_email_is_required: 'Contact email is required',
  please_enter_a_valid_email: 'Please enter a valid email',
  street_is_required: 'Street is required',
  city_name_is_required: 'City name is required',
  country_is_required: 'Country is required',
  state_is_required: 'State is required',
  add_new: 'Add new',
  stage: 'Stage',
  sku: 'SKU',
  sku_eg: 'Eg.: FW24CBSHEJ66',
  add_new_product_or_service: 'Add goods or service',
  stage_is_required: 'Stage is required',
  delete_supplier: 'Delete Supplier',
  are_you_sure_you_want_to_delete_this_supplier: 'Are you sure you want to delete this supplier ?',
  view_claim: 'View Claim',
  search_products: 'Search goods',
  create_product: 'Create goods',
  create_service: 'Create service',
  image_type: 'png, jpg, jpeg',
  are_you_sure_you_want_to_delete_this_supplier_product: 'Are you sure you want to delete this supplier product ?',
  search_services: 'Search services',
  edit_supply_chain: 'Edit Supply Chain',
  are_you_sure_you_want_to_delete_this_supply_chain: 'Are you sure you want to delete this supply chain ?',
  delete_supply_Chain: 'Delete Supply Chain',
  continue: 'Continue',
  select_the_supplier: 'Select the supplier',
  edit_product: 'Edit service',
  save_draft: 'Save Draft',
  detailed_view: 'Detailed View',
  they_are_not_always_relevant_and_not_needed_for_the_mvp: 'They are not always relevant and not needed for the MVP.',
  add_products: 'Add Products',
  select_the_supplier_product: 'Select the suppliers service',
  is_in_supply_chain: 'Is in supply chain',
  country: 'Country',
  has_suppliers_products: 'Has supplier products',
  is_selected:"Is Selected",
  sort_by: 'Sort By',
  filter: 'Filter',
  clear_all: 'Clear All',
  has_products: 'Has products',
  is_in_a_supply_chain: 'Is in a supply chain',
  service: 'Service',
  product: 'Product',
  type: 'Product type',
  has_suppliers: 'Has suppliers',
  are_you_sure_you_want_to_delete_this_chain_product: "Are you sure you want to delete this chain's Product?",
  are_you_sure_you_want_to_delete_this_chain_supplier: "Are you sure you want to delete this chain's Supplier?",
  zoom_in: 'Zoom In',
  zoom_out: 'Zoom Out',
  reset: 'Reset',
  no_certificate_found: 'No document found',
  reviewed_claims: 'REVIEWED CLAIMS',
  view_all: 'View all',
  unique_Id: 'Unique Id',
  view_docs: 'View Docs',
  select_the_value_you_want_to_communicate: 'Select the values you want to communicate. We take care of validate them and translate them in compliant claims',
  edit_supplier: 'Edit Supplier',
  submit_for_approval: 'Submit for approval',
  fill_the_forms: 'Fill the forms',
  the_forms_are_saved_automatically_You_can_submit_the_forms_once_all_the_questions_are_answered:
    'The forms below contain all the questions relevant to obtaining the selected claims, plus some generic questions. The forms are saved automatically. You can submit the forms once all the questions are answered.',
  opens_the_selected_document_for_reading_with_zoom_and_scroll_functionality_Allows_quick_access_without_making_changes:
    'In this section you can access all the documents that have been uploaded. You can easily check certifications that are about to expire, receive expiration notifications, and take actions to renew them. In addition, you have the ability to edit and update any information associated with these documents, ensuring that all data is always accurate and up-to-date.',
  documents: 'DOCUMENTS AND CERTIFICATIONS',
  create_brand: 'Create Brand',
  select_claim: 'Select Claim',
  select_supplier: 'Select Supplier',
  select_product: 'Select Product',
  filter_by: 'Filter By',
  additional_settings: 'Additional settings',
  stage_vanity_name: 'Stage Vanity Name',
  vanity_title: 'Vanity Title',
  has_not_product: 'Has not product',
  read_more: 'Read more',
  read_less: 'Read less',
  add_user: 'Add User',
  files: 'Files',
  file_type: 'File Type',
  deselect_all: 'Deselect All',
  select_all: 'Select All',
  is_archived: 'Is Archived',
  qr_code: 'QR Code',
  date: 'Date',
  only_image_files_PNG_JPEG_JPG_are_allowed: 'Only image files (PNG, JPG, JPEG) are allowed.',
  only_PDF_files_are_allowed: 'Only PDF files are allowed',
  download_qr: 'Download QR Code',
  no_category_found: 'No claims found',
  yes: 'Yes',
  no: 'No',
  collectionName_: 'collectionName',
  date_: 'date',
  title_: 'title',
  name_: 'name',
  Name: 'Name',
  Date: 'Date',
  Title: 'Title',
  Stage: 'Stage',
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
  Verified: 'Verified',
  Proven: 'Proven',
  Declared: 'Declared',
  Rejected: 'Rejected',
  additional_information: 'Additional Information',
  upload: 'Upload',
  SUPPLIER: 'SUPPLIER',
  SUPPLIER_CHAIN: 'SUPPLIER-CHAIN',
  SUPPLIER_PRODUCT: 'SUPPLIER-PRODUCT',
  BRAND_PRODUCT: 'BRAND-PRODUCT',
  Less_than_9: 'Less than 9',
  ten_to_49: '10 to 49',
  fifty_to_249: '50 to 249',
  More_than_250: 'More than 250',
  VERIFIED: 'VERIFIED',
  PROVEN: 'PROVEN',
  DECLARED: 'DECLARED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  product_: 'Product +',
  supplier_: 'Supplier +',
  email_is_required:"Email is required",
  sku_id:"Sku Id",
  collection_name:"Collection Name",
  category:"Category",
  Unselect_All:"unselect All",
  are_you_sure_you_want_to_remove_all_products: 'Are you sure to remove all the products connected to this claim?',
  remove_all_the_products_connected_to_this_claim:"Remove all the products connected to this claim",
  are_you_sure_you_want_to_remove_all_suppliers: 'Are you sure to remove all the suppliers connected to this claim?',
  remove_all_the_suppliers_connected_to_this_claim: 'Remove all the suppliers connected to this claim',
  life_cycle_assessment: 'Life Cycle Assessment',
  start_calculation: 'Start Calculation',
  upload_csv_file: 'Upload CSV',
  view_results: 'View Results',
  key_matrix: 'Key Matrix',
  total_of_all_te_elements: 'Total of all te elements',
  lca_type: 'Type',
  qty: 'Qty',
  unit: 'Unit',
  Selected: 'Selected',
  max_lca_products: 'Max LCA Products',
  product_category: 'Product Category',
  assessment_name_label: 'Assessment Name',
  assessment_name_placeholder: 'Enter Name',
  edit_draft: 'Edit Draft',
  select_product_and_continue: 'Select product and continue',
  indicator: 'Indicator',
  selected_product: 'You have selected 1 product',
  submit: 'Submit',
  your_password_reset_link_is_ready: 'YOUR PASSWORD RESET LINK IS READY.',
  just_check_your_email_for_reset_link: 'Just Check Your Email For Reset LINK.',
  forget_your_password: 'Forgot your password?',
  easy_template_download: 'Easy template download',
  easy_template_download_desc: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
        distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as
        their default model text, and a search f`,
  csv_template: 'CSV Template',
  how_to_upload_manual: 'How to upload Manual',
  how_to_upload_manual_desc: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has`,
  download_pdf: 'Download PDF',
  upload_the_template_created: 'Upload the template created',
  updating: 'Updating',
  creating: 'Creating',
  select_recommanded_products: 'Select Recommended Products',
  search_for_assessment: 'Search for assessments',
  created: 'Created',
  garment: 'Garment',
  type_of_garment: 'Type of garment',
  garment_code: 'Garment code',
  garment_title: 'Garment title',
  product_composition: 'Product Composition',
  distribution: 'Distribution',
  transport: 'Transport',
  distance: 'Distance (km)',
  packaging: 'Packaging',
  primary_packaging_used: 'Primary packaging used',
  quantity: 'Quantity',
  production_volume: ' Production volume',
  consumption_of_water: 'Consumption of water',
  consumption_of_natural_gas: 'Consumption of natural gas',
  electricity: 'Electricity',
  total_use: 'Total use (kWh)',
  use_of_electricity_from_renewable_sources: 'Use of electricity from renewable sources',
  do_you_use_electricity_from_renewable_sources: 'Do you use electricity from renewable sources?',
  use_of_electricity_from_pv: 'Use of electricity from PV',
  do_you_have_a_pv_system_installed_in_your_production_site: 'Do you have a PV system installed in your production site?',
  share_of_electricity_from_pv: 'Share of electricity from PV',
  share_of_electricity_from_pv_desc: 'Please indicate the share of electricity used in your production site that comes from your PV system.',
  location_of_renewable_electricity_supply: 'Location of renewable electricity supply',
  location_of_renewable_electricity_supply_desc: 'Please indicate the share of electricity used in your production site that comes from your PV system.',
  share_of_electricity_from_renewable_sources: 'Share of electricity from renewable sources',
  share_of_electricity_from_renewable_sources_desc: 'Please indicate the share of electricity that you buy from the national grid which comes from reneable sources',
  waste: 'Waste',
  share_of_electricity_from_renewable_sources: 'Share of electricity from renewable sources',
  waste_quantity: 'Waste quantity (kg)',
  waste_recycling: 'Waste - recycling (%)',
  waste_incineration: 'Waste - incineration',
  waste_landfill: 'Waste - landfill',
  dyeing: 'Dyeing',
  fabric_dyeing: 'Fabric dyeing',
  transportation_type: 'Transportation type',
  fabric_manufacturing: 'Fabric manufacturing',
  yarn_count: 'Yarn count (dtex)',
  yarn_manufacturing: 'yarn manufacturing',
  spinning: 'Spinning',
  raw_material_supply: 'Raw Material Supply',
  which_is_the_composition_of_the_garment_you_want_to_analyze: 'Which is the composition of the garment you want to analyze?',
  material_description: 'Material description',
  material_family: 'Material family',
  material_type: 'Material type',
  material_weight: 'Material weight',
  material_percentage: 'Material %',
  this_product_already_selected_in_supply_chain:"This product already selected in supply chain"
};
export default englishTranslation;
