import * as React from 'react';
import Popover from '@mui/material/Popover';
import { IconButton, InputLabel } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, FormControl, MenuItem, Select, Typography, ListItemText, Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import { fetchFilterProductsCollection, fetchFilterProductsIsArchive, fetchFilterProductsIsSelected, fetchFilterProductsQRId, fetchProducts, fetchSortProducts } from '../../features/productSlice';
import { useDispatch } from 'react-redux';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';

export default function FilterBrandProduct({ page, itemsPerPage, claimId, showSelected }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sort, setSort] = React.useState('');
  const [hasCollection, setHasCollection] = React.useState('');
  const [isArchived, setIsArchived] = React.useState('');
  const [isQrId, setIsQrId] = React.useState('');
  const [collection, setCollection] = React.useState([]);
  const [isSelected, setIsSelected] = React.useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    fetchCollectionList();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHasCollection = (event) => {
    const term = event.target.value;
    setHasCollection(term);
    dispatch(fetchFilterProductsCollection({ term, page, itemsPerPage }));
    setAnchorEl(null);
  };
  const handleIsArchived = (event) => {
    const term = event.target.value;
    setIsArchived(term);
    dispatch(fetchFilterProductsIsArchive({ term, page, itemsPerPage }));
    setAnchorEl(null);
  };
  const handleIsQrId = (event) => {
    const term = event.target.value;
    setIsQrId(term);
    dispatch(fetchFilterProductsQRId({ term, page, itemsPerPage }));
    setAnchorEl(null);
  };

  const fetchCollectionList = async () => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}/collection`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        setCollection(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSortChange = (event) => {
    let term = event.target.value;
    setSort(term);
    dispatch(fetchSortProducts({ term, page, itemsPerPage }));
    setAnchorEl(null);
  };

  const handleIsSelected = (value) => {
    setIsSelected(value);
    if (claimId) {
      dispatch(fetchFilterProductsIsSelected({ value, claimId, page, itemsPerPage }));
    }
    setAnchorEl(null);
  };

  const handleClearAll = () => {
    dispatch(fetchProducts({ page, itemsPerPage }));
    setSort('');
    setHasCollection('');
    setIsArchived('');
    setIsQrId('');
    setAnchorEl(null);
    setIsSelected('');
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} sx={{ bgcolor: '#0e1090', borderRadius: '30px', ':hover': { bgcolor: '#0e1090' } }}>
        <FilterListIcon sx={{ color: '#fff' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
              {t('filter')} <FilterAltIcon sx={{ fontSize: '20px' }} />
            </Typography>
            <Button onClick={handleClearAll} sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize', color: '#0e1090' }}>
              {t('clear_all')}
            </Button>
          </Box>
          <InputLabel sx={{ fontSize: '14px' }}>{t('collection')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={hasCollection} onChange={handleHasCollection}>
              {collection?.map((name) => (
                <MenuItem key={name} value={name} sx={{ fontSize: '12px' }}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel sx={{ fontSize: '14px' }}>{t('is_archived')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={isArchived} onChange={handleIsArchived}>
              {[t('yes'), t('no')].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showSelected && (
            <>
              <InputLabel sx={{ fontSize: '14px' }}>{t('is_selected')}</InputLabel>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Select variant="standard" size="small" value={isSelected} onChange={(e) => handleIsSelected(e.target.value)}>
                  {[t('yes'), t('no')].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <InputLabel sx={{ fontSize: '14px' }}>{t('qr_code')}</InputLabel>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={isQrId} onChange={handleIsQrId}>
              {[t('yes'), t('no')].map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            {t('sort_by')} <SortIcon />
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select variant="standard" size="small" value={sort} onChange={handleSortChange}>
              {[t('title_'), t('collectionName_'), t('date_')].map((sortOption) => (
                <MenuItem key={sortOption} value={sortOption} sx={{ textTransform: 'capitalize' }}>
                  {sortOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
}
