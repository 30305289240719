import { Box, Button, Card, CardContent, DialogActions, DialogContentText, Grid, IconButton, InputLabel, Popover, Snackbar, TextareaAutosize, Typography } from '@mui/material';
import CommonDialog from '../utils/CommonDialog';
import { CommonButton } from '../utils/CommonButton';
import { useEffect, useRef, useState } from 'react';
import CommonInput from '../utils/CommonInput';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CommonSelect from '../utils/CommonSelect';
import CommonOption from '../utils/commonOption';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { RxTarget } from 'react-icons/rx';
import EditIcon from '@mui/icons-material/Edit';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import CertificateLibrary from './CertificateLibrary';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArticleIcon from '@mui/icons-material/Article';
import { IoMdArrowBack } from 'react-icons/io';
import AlertDialog from '../utils/AlertDialog';
import axios from 'axios';
import { fetchGoalsCertificates } from '../../features/certificateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClaimCategoryForm } from '../../features/categoryClaimFormSlice';
import { fetchQuestionForm } from '../../features/questionsFormSlice';
import { useNavigate, useParams } from 'react-router-dom';
import PATH from '../utils/path';
import { decryptId } from '../utils/encryptDecrypt';
import { RolesStatus } from '../../constants/common.constants';
import MultiSelectOption from '../utils/MultiSelectOption';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';

export default function QuestionForm() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const formId = decryptId(params?.id);
  const user = useSelector((store) => store.user);
  const fileInputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionFormData, setQuestionFormData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreateNewDialog, setOpenCreateNewDialog] = useState(false);
  const [questionId, setQuestionId] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [errors, setErrors] = useState({});
  const [certificateID, setCertificateID] = useState(null);
  const [openDeleteCertificateDialog, setOpenDeleteCertificateDialog] = useState(false);
  const [updateDocument, setUpdateDocument] = useState(false);
  const [modelFormData, setModelFormData] = useState({
    attachment: null,
    name: '',
    documentType: '',
    emittedBy: '',
    emissionDate: '',
    expirationDate: '',
    certificateOwner: '',
  });
  const [extraNote, setExtraNote] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [formDescription, setFormDescription] = useState('');
  const dispatch = useDispatch();
  const { questionForm, loading } = useSelector((state) => state.questionForm);
  const { claimCategoryForm } = useSelector((state) => state.categoryClaimForm);
  const { certificateGoals } = useSelector((state) => state.certificatesGoals);
  const [loadingBtn, setLoadingBtn] = useState(false);

  let formdata;
  if (formId) {
    formdata = questionForm;
  } else {
    formdata = certificateGoals?.goals;
  }
  const closeDialog = () => {
    setOpenDialog(false);
    setAnchorEl(false);
  };

  useEffect(() => {
    dispatch(fetchClaimCategoryForm());
  }, [dispatch]);

  useEffect(() => {
    const selectedForm = claimCategoryForm?.forms?.find((form) => form?.id === formId);
    setFormTitle(selectedForm?.title);
    setFormDescription(selectedForm?.description);
  }, [claimCategoryForm, formId]);

  const closeCreateNewDialog = () => {
    // setOpenCreateNewDialog(false);
    setAnchorEl(false);
    setImageUrl('');
    setErrors({});
    setModelFormData({});
    setCertificateID(null);
    setQuestionId('');
    setUpdateDocument(false);
  };
  const handleClick = (e, id) => {
    setAnchorEl(e.currentTarget);
    setQuestionId(id);
  };
  const closePopover = () => {
    setAnchorEl(false);
  };
  useEffect(() => {
    if (formId) {
      dispatch(fetchQuestionForm(formId));
    } else {
      dispatch(fetchGoalsCertificates());
    }
  }, [dispatch, formId]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const timeoutRef = useRef(null);
  const latestFormDataRef = useRef(questionFormData);
  const handleClose = () => {
    if (user.userType === RolesStatus.client) {
      if (params?.id === 'goals') {
        navigate(PATH.HOME);
      } else {
        navigate(PATH.CLAIMMANAGEMENTFORM);
      }
    } else {
      navigate(PATH.HOME);
    }
    dispatch(fetchGoalsCertificates());
    dispatch(fetchClaimCategoryForm());
    setCurrentPage(1);
  };

  const handleQuestionAnswer = async (queId, formData) => {
    if (!checkOnlineStatus()) return; 
    try {
      const response = await httpService.post(`${RestUrlsConstants.formQueUrl}/${queId}`, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        if (params?.id === 'goals') {
          dispatch(fetchGoalsCertificates());
        } else {
          dispatch(fetchQuestionForm(formId));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInput = (newValue, id) => {
    latestFormDataRef.current = {
      ...latestFormDataRef.current,
      [id]: newValue,
    };
    setQuestionFormData(latestFormDataRef.current);
    const formData = new FormData();
    formData.append('value', latestFormDataRef.current[id]);
    handleQuestionAnswer(id, formData);
  };

  const handleTextArea = (newValue, id) => {
    latestFormDataRef.current = {
      ...latestFormDataRef.current,
      [id]: newValue,
    };
    setExtraNote(latestFormDataRef.current);
    const formData = new FormData();
    formData.append('extraNotesValue', latestFormDataRef.current[id]);
    handleQuestionAnswer(id, formData);
  };

  const handleInputChange = (event, name) => {
    const { value, type } = event.target;
    setErrors({
      ...errors,
      [name]: '',
    });
    if (type === 'file') {
      setModelFormData({
        ...modelFormData,
        [name]: event.target.files[0],
      });
    } else {
      setModelFormData({
        ...modelFormData,
        [name]: value,
      });
    }
  };

  // const handleFileInput = (e) => {
  //   const file = e.target.files[0];
  //   setErrors({
  //     ...errors,
  //     attachment: '',
  //   });
  //   setModelFormData((prevState) => ({
  //     ...prevState,
  //     attachment: file,
  //   }));
  //   setImageUrl(URL.createObjectURL(file));
  // };
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    const invalidFormats = ['.avi', '.mkv', '.flv', '.wmv'];
    if (file) {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (invalidFormats.includes(fileExtension)) {
        setErrors({
          ...errors,
          attachment: 'Document format is invalid.',
        });
        setModelFormData((prevState) => ({
          ...prevState,
          attachment: null,
        }));
        setImageUrl(null);
        return;
      }
      setErrors({
        ...errors,
        attachment: '',
      });
      setModelFormData((prevState) => ({
        ...prevState,
        attachment: file,
      }));
      setImageUrl(URL.createObjectURL(file));
    }
  };
  const handleCreate = async () => {
    const newErrors = {};
    if (!updateDocument) {
      if (!modelFormData.attachment) newErrors.attachment = t('attachment_is_required');
    }
    if (!modelFormData.name) newErrors.name = t('name_is_required');
    if (!modelFormData.documentType) newErrors.documentType = t('document_type_is_required');
    if (!modelFormData.emittedBy) newErrors.emittedBy = t('emitted_by_is_required');
    if (!modelFormData.certificateOwner) newErrors.certificateOwner = t('certificate_owner_is_required');

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoadingBtn(true);
      const formData = new FormData();
      if (modelFormData.attachment) {
        formData.append('documents', modelFormData.attachment);
      }
      if (!updateDocument) {
        Object.keys(modelFormData).forEach((key) => {
          const value = modelFormData[key];
          if (value !== null && typeof value === 'object' && key !== 'attachment') {
            Object.keys(value).forEach((subKey) => {
              formData.append(`documents[${key}][${subKey}]`, value[subKey]);
            });
          } else if (key !== 'attachment') {
            formData.append(`documents[${key}]`, value);
          }
        });
      } else {
        if (modelFormData.name) {
          formData.append('name', modelFormData.name);
        }
        if (modelFormData.documentType) {
          formData.append('documentType', modelFormData.documentType);
        }
        if (modelFormData.emittedBy) {
          formData.append('emittedBy', modelFormData.emittedBy);
        }
        if (modelFormData.emissionDate) {
          formData.append('emissionDate', modelFormData.emissionDate);
        }
        if (modelFormData.expirationDate) {
          formData.append('expirationDate', modelFormData.expirationDate);
        }
        if (modelFormData.certificateOwner) {
          formData.append('certificateOwner', modelFormData.certificateOwner);
        }
      }
      try {
        if (updateDocument) {
          await handleUpdateDocument(formData);
        } else {
          await handleQuestionAnswer(questionId, formData);
        }
        setOpenCreateNewDialog(false);
        setModelFormData({});
        setImageUrl('');
        setCertificateID(null);
        setUpdateDocument(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBtn(false); // End loading state
      }
    }
  };
  const handleUpdateDocument = async (formData) => {
    if (!checkOnlineStatus()) return; 
    try {
      const response = await httpService.patch(`${RestUrlsConstants.certificateVarify}/${certificateID}`, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        // setSnackBar({ open: true, message: response?.data?.message });
        dispatch(fetchQuestionForm(formId));
        setCertificateID(null);
        setUpdateDocument(false);
      }
    } catch (error) {
      console.log(error);
      // setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleSetGoals = (id, currentValue) => {
    const newGoalValue = !currentValue;
    const formData = {
      setAsFutureGoal: newGoalValue,
    };
    handleQuestionAnswer(id, formData);
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const totalPages = Math.ceil(formdata?.length / 10);
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  function getFileExtension(url) {
    const extensionRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    const match = extensionRegex.exec(url);
    return match ? match[1].toLowerCase() : '';
  }
  
  function renderFileIcon(val) {
    const extension = getFileExtension(val?.urls[0]);
  
    // Directly display icons for specific file types without preview
    const fileTypesWithoutPreview = ['csv', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'xlsx','xls','pdf'];
    
    if (fileTypesWithoutPreview.includes(extension)) {
      return (
        <>
          <ArticleIcon style={{ fontSize: '50px' }} />
          <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{extension}</span>
        </>
      );
    }
  
    // Display preview image for other file types if available
    return val?.preview ? (
      <img height={120} width={100} alt="preview-img" src={val.preview} />
    ) : (
      <>
        <ArticleIcon style={{ fontSize: '50px' }} />
        <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{extension}</span>
      </>
    );
  }

  const handleReplaceFile = (val) => {
    // setOpenCreateNewDialog(true);
    setImageUrl(val?.urls[0]);
    setQuestionId(val?.questionId);
    setCertificateID(val?.id);
    setModelFormData({
      // attachment: val?.urls[0],
      name: val?.name,
      documentType: val?.documentType,
      emittedBy: val?.emittedBy,
      emissionDate: val?.emissionDate?.slice(0, 10),
      expirationDate: val?.expirationDate?.slice(0, 10),
      certificateOwner: val?.certificateOwner,
    });
    setUpdateDocument(true);
  };
  const handleDelete = (queId, certId) => {
    setCertificateID(certId);
    setQuestionId(queId);
    setOpenDeleteCertificateDialog(true);
  };

  const deleteCertificate = async () => {
    if (!checkOnlineStatus()) return; 
    try {
      const payload = {
        certificateId: certificateID,
      };
      const url = `${RestUrlsConstants.formQueUrl}/${questionId}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
        data: payload,
      });
      if (response?.data?.status) {
        // setSnackBar({ open: true, message: response?.data?.message });
        setOpenDeleteCertificateDialog(false);
        setCertificateID(null);
        setQuestionId('');
        dispatch(fetchQuestionForm(formId));
      }
      console.log('Delete successful:', response.data);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleCloseDialog = async () => {
    setOpenDeleteCertificateDialog(false);
    setCertificateID(null);
    setQuestionId('');
  };

  const indexOfLastQuestion = currentPage * 10;
  const indexOfFirstQuestion = indexOfLastQuestion - 10;
  const currentQuestionForm = formdata?.slice(indexOfFirstQuestion, indexOfLastQuestion);
  return (
    <>
      <Button
        startIcon={<IoMdArrowBack />}
        onClick={() => handleClose()}
        sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2,mb:3  }}
      >
        {t('back')}
      </Button>
      {formId && (
        <Card sx={{ boxShadow: 'none', mb: 2 }}>
          <CardContent>
            <Typography sx={{ fontWeight: '600', fontSize: '16px', display: 'flex', gap: '10px', color: '#0e1090' }}>{formTitle}</Typography>
            <Typography sx={{ color: 'gray', fontWeight: '600', fontSize: '14px' }}>{formDescription}</Typography>
          </CardContent>
        </Card>
      )}
      <Card>
        <CardContent>
          <form>
            <Box>
              {currentQuestionForm?.map((form, index) => {
                const answer = form?.answer?.value;
                return (
                  <Box key={index}>
                    {['text', 'TEXT', 'number'].includes(form?.question?.type) && (
                      <CommonInput
                        label={form?.question?.title}
                        value={questionFormData[form?.question?.id] || answer || ''}
                        type={form?.question?.type}
                        tooltip={form?.question?.tooltipText}
                        variant="standard"
                        autoComplete={false}
                        handleChange={(e) => handleInput(e.target.value, form?.question?.id)}
                      />
                    )}

                    {form?.question?.type === 'dropdown' && (
                      <CommonSelect
                        tooltip={form?.question?.tooltipText}
                        label={form?.question?.title}
                        options={form?.question?.validation?.validOptions}
                        value={questionFormData[form?.question?.id] || answer || ''}
                        handleChange={(e) => handleInput(e.target.value, form?.question?.id)}
                      />
                    )}
                    {form?.question?.type === 'options' &&
                      (form?.question?.validation?.isMultiOption ? (
                        <MultiSelectOption
                          tooltip={form?.question?.tooltipText}
                          label={form?.question?.title}
                          options={form?.question?.validation?.validOptions}
                          value={questionFormData[form?.question?.id] || answer || ''}
                          handleChange={(selectedValue) => handleInput(selectedValue, form?.question?.id)}
                        />
                      ) : (
                        <CommonOption
                          tooltip={form?.question?.tooltipText}
                          label={form?.question?.title}
                          options={form?.question?.validation?.validOptions}
                          value={questionFormData[form?.question?.id] || answer || ''}
                          handleChange={(e) => handleInput(e.target.value, form?.question?.id)}
                        />
                      ))}

                    {form?.question?.showSetAsFutureGoal && (
                      <Box>
                        <Button
                          variant="outlined"
                          sx={{
                            color: form?.answer?.setAsFutureGoal ? '#fff' : '#0e1090',
                            fontWeight: '600',
                            mt: 2,
                            px: 3,
                            background: form?.answer?.setAsFutureGoal ? '#0e1090' : '#f6f6ee',
                            borderRadius: '15px',
                            border: '1px solid gray',
                            ':hover': { background: form?.answer?.setAsFutureGoal ? '#0e1090' : '#fff' },
                            display: 'flex',
                            gap: '10px',
                            fontSize: '12px',
                          }}
                          onClick={() => handleSetGoals(form?.question?.id, form?.answer?.setAsFutureGoal)}
                        >
                          <RxTarget style={{ fontSize: '18px' }} /> {form?.answer?.setAsFutureGoal ? t('you_have_set_this_as_a_goal') : t('set_as_future_goal')}
                        </Button>
                      </Box>
                    )}
                    {form?.question?.extraNotes && (
                      <Box mt={2}>
                        <Typography sx={{ color: 'gray', fontWeight: '600', fontSize: '14px' }}>{t('extra_notes')}</Typography>
                        <TextareaAutosize
                          id={`textarea-${form?.question?.id}`}
                          aria-label="minimum height"
                          minRows={1}
                          placeholder={t('extra_notes') + '...'}
                          style={{
                            border: 'none',
                            width: '100%',
                            borderBottom: '1px solid gray',
                          }}
                          value={extraNote[form?.question?.id] || form?.answer?.extraNotesValue || ''}
                          onChange={(e) => handleTextArea(e.target.value, form?.question?.id)}
                        />
                      </Box>
                    )}
                    {form?.question?.fileUpload && (
                      <Box mt={3}>
                        <Grid container>
                          {form?.answer?.documents?.map((val, index) => (
                            <>
                              {(val?.urls[0]?.includes('.pdf') ||
                                val?.urls[0]?.includes('.doc') ||
                                val?.urls[0]?.includes('.docx') ||
                                val?.urls[0]?.includes('.csv') ||
                                val?.urls[0]?.includes('.ppt') ||
                                val?.urls[0]?.includes('.pptx') ||
                                val?.urls[0]?.includes('.xlsx') ||
                                val?.urls[0]?.includes('.txt') ||
                                val?.urls[0]?.startsWith('https://static.renoon.com')) && (
                                <Grid key={index} item xs={2} sx={{ px: 1, mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <Box
                                    sx={{
                                      height: '120px',
                                      width: '100px',
                                      border: '1px solid gray',
                                      bgcolor: '#fff',
                                      borderRadius: '8px',
                                      textAlign: 'justify',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <ArticleIcon style={{ fontSize: '50px' }} />
                                    <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{getFileExtension(val?.urls[0])}</span>
                                  </Box>
                                  <Box>
                                    <Typography sx={{ fontSize: '12px', color: '#000', textAlign: 'center', width: '70px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                      {val?.name}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', gap: '10px' }}>
                                    <IconButton
                                      onClick={() => {
                                        handleReplaceFile(val);
                                      }}
                                      size="small"
                                      sx={{ bgcolor: '#0e1090', ':hover': { bgcolor: '#0e1090' } }}
                                    >
                                      <FileUploadIcon sx={{ color: '#fff', fontSize: '16px' }} />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(form?.question?.id, val?.id)} size="small" sx={{ bgcolor: '#EC895A', ':hover': { bgcolor: '#EC895A' } }}>
                                      <DeleteIcon sx={{ color: '#fff', fontSize: '16px', fontStyle: '' }} />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )}
                              {(val?.urls[0]?.includes('.png') || val?.urls[0]?.includes('.jpeg') || val?.urls[0]?.includes('.jpg')) && (
                                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'nowrap', px: 1, mt: 1 }}>
                                  <Box>
                                    <img src={val?.preview} alt="" height={'120px'} width={'100px'} style={{ borderRadius: '8px', border: '1px solid gray' }} />
                                  </Box>
                                  <Box>
                                    <Typography sx={{ fontSize: '12px', color: '#000', textAlign: 'center', width: '70px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                      {val?.name}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', gap: '10px' }}>
                                    <IconButton
                                      onClick={() => {
                                        handleReplaceFile(val);
                                      }}
                                      size="small"
                                      sx={{ bgcolor: '#0e1090', ':hover': { bgcolor: '#0e1090' } }}
                                    >
                                      <FileUploadIcon sx={{ color: '#fff', fontSize: '16px' }} />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(form?.question?.id, val?.id)} size="small" sx={{ bgcolor: '#EC895A', ':hover': { bgcolor: '#EC895A' } }}>
                                      <DeleteIcon sx={{ color: '#fff', fontSize: '16px', fontStyle: '' }} />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )}

                              {(val?.urls[0]?.includes('.mp4') || val?.urls[0]?.includes('.mov')||val?.urls[0]?.includes('.webm')) && (
                                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexWrap: 'nowrap', px: 1, mt: 1 }}>
                                  <Box sx={{ position: 'relative' }}>
                                    <video src={val?.preview} alt="" height={'120px'} width={'100px'} style={{ objectFit: 'cover', borderRadius: '8px', border: '1px solid gray' }} muted />
                                    <PlayCircleOutlineIcon
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '30px',
                                        color: 'white',
                                        opacity: 0.8,
                                      }}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography sx={{ fontSize: '12px', color: '#000', textAlign: 'center', width: '70px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                      {val?.name}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', gap: '10px' }}>
                                    <IconButton
                                      onClick={() => {
                                        handleReplaceFile(val);
                                      }}
                                      size="small"
                                      sx={{ bgcolor: '#0e1090', ':hover': { bgcolor: '#0e1090' } }}
                                    >
                                      <FileUploadIcon sx={{ color: '#fff', fontSize: '16px' }} />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(form?.question?.id, val?.id)} size="small" sx={{ bgcolor: '#EC895A', ':hover': { bgcolor: '#EC895A' } }}>
                                      <DeleteIcon sx={{ color: '#fff', fontSize: '16px', fontStyle: '' }} />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )}
                            </>
                          ))}
                        </Grid>
                        {form?.question?.type === 'file' && <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{form?.question?.title}</InputLabel>}
                        <Button
                          sx={{
                            marginTop: '20px',
                            height: '150px',
                            width: '100%',
                            color: 'black',
                            boxShadow: 'none',
                            border: '2px dashed gray',
                            background: `#eeeeee`,
                            textTransform: 'none',
                            fontWeight: 'bold',
                            ':hover': { background: '#eeeeee', boxShadow: 'none' },
                          }}
                          variant="contained"
                          onClick={(e) => handleClick(e, form?.question?.id)}
                        >
                          <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                            <Box sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                              <CloudUploadIcon sx={{ color: 'gray', fontSize: '40px' }} />
                              {t('choose_file')}
                              <span style={{ fontSize: '12px', fontWeight: 'normal' }}>{t('file_types')} </span>
                            </Box>
                          </Typography>
                        </Button>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </form>

          <DialogActions sx={{ mt: 2 }}>
            <Box mt={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span style={{ fontSize: '14px', color: 'gray' }}>
                  {t('page')} : {currentPage}/{totalPages}
                </span>
                <Box sx={{ display: 'flex', gap: '5px' }}>
                  <Button
                    sx={{ background: '#000', textTransform: 'capitalize', ':hover': { background: '#101012' } }}
                    size="small"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    variant="contained"
                    startIcon={<KeyboardArrowLeftIcon />}
                  >
                    {t('prev')}
                  </Button>
                  <Button
                    sx={{ background: '#000', textTransform: 'capitalize', ':hover': { background: '#101012' } }}
                    size="small"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    variant="contained"
                    endIcon={<KeyboardArrowRightIcon />}
                  >
                    {t('next')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogActions>
        </CardContent>
      </Card>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography
          onClick={() => {
            setOpenDialog(true);
            closePopover();
            dispatch(fetchGoalsCertificates());
          }}
          sx={{ p: 1, cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
        >
          {t('pick_from_library')}
        </Typography>
        <Typography
          onClick={() => {
            setOpenCreateNewDialog(true);
            closePopover();
          }}
          sx={{ p: 1, cursor: 'pointer', fontWeight: '600', fontSize: '14px' }}
        >
          {t('create_new')}
        </Typography>
      </Popover>
      <CertificateLibrary formId={formId} questionId={questionId} openDialog={openDialog} closeDialog={closeDialog} />
      <CommonDialog
        heading={
          <span>
            {t('certificate_details')} <IoIosInformationCircleOutline />
          </span>
        }
        open={openCreateNewDialog}
        handleClose={closeCreateNewDialog}
      >
        <DialogContentText sx={{ width: '500px' }}>
          <form>
            <InputLabel>{t('attachment') + '*'}</InputLabel>
            <input ref={fileInputRef} required multiple type="file" hidden onChange={handleFileInput} />
            <Button
              sx={{
                height: '200px',
                width: '300px',
                color: '#000',
                background: imageUrl ? `url(${imageUrl}) center center / cover no-repeat` : '#EFEFEF',
                ':hover': { background: imageUrl ? `url(${imageUrl}) center center / cover no-repeat` : '#ededed' },
              }}
              variant="contained"
              onClick={() => fileInputRef.current.click()}
            >
              {!imageUrl && <EditIcon />}
              {(imageUrl?.includes('.pdf') || imageUrl?.includes('.doc') || imageUrl?.includes('.docx') || imageUrl?.includes('.csv')) && getFileExtension(imageUrl)}
              {!updateDocument && <Typography sx={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{imageUrl}</Typography>}
            </Button>
            {errors.attachment && (
              <Typography color="error" fontSize={'12px'}>
                {errors.attachment}
              </Typography>
            )}
            <CommonInput
              label={t('insert_name') + '*'}
              placeholder={t('insert_name')}
              type="text"
              variant="standard"
              name="name"
              value={modelFormData.name}
              handleChange={(e) => handleInputChange(e, 'name')}
            />
            {errors.name && (
              <Typography color="error" fontSize={'12px'}>
                {errors.name}
              </Typography>
            )}
            <CommonSelect
              options={['Certification', 'Proof point']}
              name="documentType"
              label={t('select_type') + '*'}
              value={modelFormData.documentType}
              handleChange={(e) => handleInputChange(e, 'documentType')}
            />
            {errors.documentType && (
              <Typography color="error" fontSize={'12px'}>
                {errors.documentType}
              </Typography>
            )}

            <CommonInput
              required={true}
              label={t('emitted_by') + '*'}
              placeholder={t('emitted_by')}
              type="text"
              variant="standard"
              name="emittedBy"
              value={modelFormData.emittedBy}
              handleChange={(e) => handleInputChange(e, 'emittedBy')}
            />
            {errors.emittedBy && (
              <Typography color="error" fontSize={'12px'}>
                {errors.emittedBy}
              </Typography>
            )}
            <CommonInput
              required={true}
              label={t('insert_date_of_emission')}
              type="date"
              variant="standard"
              name="emissionDate"
              value={modelFormData.emissionDate}
              handleChange={(e) => handleInputChange(e, 'emissionDate')}
            />

            <CommonInput
              label={t('insert_expiration_date')}
              type="date"
              variant="standard"
              name="expirationDate"
              value={modelFormData.expirationDate}
              handleChange={(e) => handleInputChange(e, 'expirationDate')}
            />

            <CommonSelect
              options={['Supplier', 'Brand']}
              name="certificateOwner"
              label={t('select_certificate_owner') + '*'}
              value={modelFormData.certificateOwner}
              handleChange={(e) => handleInputChange(e, 'certificateOwner')}
            />
            {errors.certificateOwner && (
              <Typography color="error" fontSize={'12px'}>
                {errors.certificateOwner}
              </Typography>
            )}
            <Box mt={2} sx={{ display: 'flex', justifyContent: 'end' }}>
              <CommonButton
                buttonName={
                  loadingBtn ? (
                    <>
                      <CircularProgress size={20} sx={{ color: 'white', mr: 1 }} />
                      {t('creating')+'...'}
                    </>
                  ) : (
                    t('create')
                  )
                }
                handleClick={handleCreate}
                disabled={loading} 
              />
            </Box>
          </form>
        </DialogContentText>
      </CommonDialog>
      <AlertDialog
        btnName={t('delete')}
        title={t('delete_certificate')}
        description={t('are_you_sure_you_want_to_delete_this_certificate')}
        open={openDeleteCertificateDialog}
        handleClick={deleteCertificate}
        handleCloseDialog={handleCloseDialog}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
}
