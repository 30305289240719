import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Checkbox, Pagination, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CommonButton } from '../utils/CommonButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import PATH from '../utils/path';
import { encryptId } from '../utils/encryptDecrypt';
import FilterProduct from './FilterProduct';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Loader from '../utils/loader';
import UploadCSVFileDialog from '../supply_chain_management/UploadCSVFileDialog';
import { saveAs } from 'file-saver';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';

const ProductEngine = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [currentPage, setCurrentPage] = useState(Number(params?.page) || 1);
  let page = currentPage;
  const [searchValue, setSearchedValue] = useState('');
  const [selected, setSelected] = useState([]);
  const [products, setProducts] = useState([]);
  const [sortProduct, setSortProduct] = useState('');
  const [porductCount, setProductCount] = useState();
  const [orderBy, setOrderBy] = useState('asc');
  const [loading, setLoading] = useState(false);
  const [currentProduct, setCurrentProduct] = useState();
  const [openUploadCSVSupplier, setOpenUploadCSVSupplier] = useState(false);

  const createProduct = () => {
    navigate(`${PATH.CREATEPRODUCT}`);
  };
  const handleUpdateProduct = (id) => {
    if (!checkOnlineStatus()) return;
    navigate(`${PATH.UPDATEPRODUCT}/${encryptId(id)}/${currentPage}`);
  };

  const fetchProducts = async (pageNo) => {
    if (!checkOnlineStatus()) return;
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}?page=${pageNo}&limit=10`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        setProducts(response?.data?.data?.products);
        setProductCount(response?.data?.data?.totalRecords);
        setCurrentProduct(response?.data?.data?.currentRecords);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = async (term) => {
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}?query=${term}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data) {
        setProducts(response?.data?.data?.products);
        setProductCount(response?.data?.data?.totalRecords);
        setCurrentProduct(response?.data?.data?.currentRecords);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterProductCollection = async (hasCollection, isArchived, isQrId) => {
    setLoading(true);
    try {
      const collectionQuery = hasCollection && `collection=${hasCollection}&`;
      const isArchivedQuery = isArchived && `isArchived=${isArchived === 'Yes' ? true : false}&`;
      const qrQuery = isQrId && `qr=${isQrId === 'Yes' ? true : false}`;

      const response = await httpService.get(`${RestUrlsConstants.product}/?${collectionQuery}${isArchivedQuery}${qrQuery}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });

      if (response?.data?.status) {
        setProducts(response?.data?.data?.products);
        setProductCount(response?.data?.data?.totalRecords);
        setCurrentProduct(response?.data?.data?.currentRecords);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSortProduct = (sort) => {
    const newOrderBy = orderBy === 'asc' ? 'desc' : 'asc';
    setOrderBy(newOrderBy);
    setSortProduct(sort);
    fetchSortedProducts(newOrderBy, sort);
  };

  const fetchSortedProducts = async (order, sort) => {
    setLoading(true);
    try {
      const response = await httpService.get(`${RestUrlsConstants.product}?orderBy=${order}&sortBy=${sort}&page=${page}&limit=10`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        setProducts(response?.data?.data?.products);
        setProductCount(response?.data?.data?.totalRecords);
        setCurrentProduct(response?.data?.data?.currentRecords);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchValue) {
      handleSearchChange(searchValue);
    } else {
      fetchProducts(page);
    }
  }, [searchValue, page, currentPage]);

  const totalPages = Math.ceil(porductCount / 10);
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCertificateDownload = async () => {
    if (!checkOnlineStatus()) return;

    const payload = {
      productIds: selected,
    };
    try {
      const response = await httpService.post(`${RestUrlsConstants.QRDOWNLOAD}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      });

      console.log(response);
      const contentType = response.headers['content-type'];
      const contentDisposition = response.headers['content-disposition'];
      const fileNameMatch = contentDisposition?.match(/filename="(.+)"/);

      let fileName = '';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const saveFile = (blobType, fileUrl) => {
        const blob = new Blob([response.data], { type: blobType });
        console.log(response, '&&&&&&&&&&&&ss');

        if (contentType === 'application/pdf') {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          saveAs(blob, fileName);
        } else if (contentType === 'application/zip') {
          const blob = new Blob([response.data], { type: 'application/zip' });
          saveAs(blob, fileName);
        }
      };

      switch (contentType) {
        case 'application/pdf':
          saveFile('application/pdf', fileNameMatch);
          break;
        case 'application/zip':
          saveFile('application/zip', fileNameMatch);
          break;
        default:
          console.error('Unexpected content type:', contentType);
          break;
      }

      setSelected([]);
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.status === 500 && 'Oops! Something went wrong. Please try again.';

      setSnackBar({ open: true, message: errorMessage });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = products.filter((product) => product.qrId).map((product) => product.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
      <Card sx={{ borderRadius: '20px' }}>
        <CardContent>
          <Box>
            <Box display="flex" justifyContent="space-between" width="full" alignItems="center">
              <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>{t('uploaded_products')} </Typography>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                {selected.length > 0 && (
                  <Button variant="contained" color="success" size="small" sx={{ textTransform: 'capitalize', borderRadius: '10px' }} onClick={handleCertificateDownload}>
                    {t('download_qr')}
                  </Button>
                )}
                <Typography sx={{ fontSize: '12px' }}>
                  {t('total')}: {porductCount}{' '}
                </Typography>
                <CommonButton buttonName={t('new_product')} handleClick={() => createProduct()} />

                <CommonButton buttonName={t('upload_product')} handleClick={() => setOpenUploadCSVSupplier(true)} />
              </Box>
              <UploadCSVFileDialog setOpenUploadCSVSupplier={setOpenUploadCSVSupplier} openUploadCSVSupplier={openUploadCSVSupplier} />
            </Box>

            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '20px' }}>
              <TextField
                size="small"
                variant="outlined"
                placeholder={t('search')}
                value={searchValue}
                sx={{
                  borderRadius: '20px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                  },
                }}
                onChange={(e) => setSearchedValue(e.target.value)}
              />
              <FilterProduct fetchProducts={fetchProducts} handleFilterProductCollection={handleFilterProductCollection} currentPage={currentPage} />
            </Box>
            <TableContainer>
              <Table sx={{ cursor: 'pointer' }}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selected.length > 0 && selected.length < products.filter((product) => product.qrId).length}
                        checked={products.filter((product) => product.qrId).length > 0 && selected.length === products.filter((product) => product.qrId).length}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('image')}</TableCell>
                    <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('e_commerce_id')}</TableCell>
                    <TableCell sx={{ fontWeight: '700', fontSize: '12.5px', textTransform: 'capitalize' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                          cursor: 'pointer',
                          textTransform: 'capitalize',
                        }}
                        onClick={() => handleSortProduct('title')}
                      >
                        {t('title')}
                        {orderBy === 'asc' || sortProduct !== 'title' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('qr_id')}</TableCell>
                    <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('QR Code')}</TableCell>
                    <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleSortProduct('collectionName')}
                      >
                        {t('collection')}
                        {orderBy === 'asc' || sortProduct !== 'collectionName' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
                      </Box>
                    </TableCell>
                    {/* <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('archive')}</TableCell> */}
                    <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell sx={{ borderBottom: 'none' }} colSpan={8}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {products?.length > 0 ? (
                        <>
                          {products?.map((item, index) => {
                            const isItemSelected = isSelected(item.id);
                            return (
                              <TableRow key={index} sx={{ ':hover': { background: '#EBEBEB' } }} role="checkbox" aria-checked={isItemSelected} selected={isItemSelected}>
                                <TableCell
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (item?.qrUrl) {
                                      handleClick(item.id);
                                    }
                                  }}
                                  padding="checkbox"
                                >
                                  {item?.qrUrl && <Checkbox checked={item?.qrUrl && isItemSelected} />}
                                </TableCell>
                                <TableCell sx={{ fontWeight: '700', fontSize: '12px', width: 100 }}>
                                  {item?.urls?.length > 0 && (
                                    <Box sx={{ height: '50px', border: '1px solid gray' }}>
                                      <img
                                        src={item?.urls[0]}
                                        alt=""
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'contain',
                                          objectPosition: 'center',
                                        }}
                                      />
                                    </Box>
                                  )}
                                </TableCell>
                                <TableCell sx={{ fontWeight: '700', fontSize: '12px', maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                  <Tooltip
                                    placement="top"
                                    title={item?.ecommerceId?.map((val, index) => (
                                      <>
                                        {index > 0 && ', '}
                                        {val === 'null' || val === 'undefined' ? '' : val}
                                      </>
                                    ))}
                                  >
                                    {item?.ecommerceId?.map((val, index) => (
                                      <>
                                        {index > 0 && ', '}
                                        {val === 'null' || val === 'undefined' ? '' : val}
                                      </>
                                    ))}
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{item?.title?.value}</TableCell>
                                <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{item?.qrId}</TableCell>
                                <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>{item?.qrUrl && <img src={item?.qrUrl} alt="" width={'50px'} height={'50px'} />}</TableCell>
                                <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>
                                  {item?.collectionName === 'null' || item?.collectionName === 'undefined' ? '' : item?.collectionName}
                                </TableCell>
                                {/* <TableCell sx={{ fontWeight: '700', fontSize: '12px' }}>
                                  <Chip size="small" label={'Archive'} sx={{ bgcolor: item?.isArchived ? '#5a5a44' : 'lightgray', fontSize: '12px' }} />
                                </TableCell> */}
                                <TableCell>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                      fontSize: '12px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '5px',
                                      bgcolor: '#000',
                                      textTransform: 'capitalize',
                                      ':hover': { bgcolor: '#222' },
                                    }}
                                    onClick={() => {
                                      handleUpdateProduct(item?.id);
                                    }}
                                  >
                                    <FontAwesomeIcon style={{ color: '#fff', fontSize: '12px' }} icon={faPenToSquare} /> {t('edit')}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell sx={{ borderBottom: 'none' }} colSpan={8}>
                            <Box sx={{ py: 2 }}>
                              <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('no_product_found')}</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              {porductCount > 0 && (
                <Pagination
                  sx={{
                    '& .MuiPaginationItem-root': {
                      color: 'gray',
                    },
                    '& .MuiPaginationItem-root.Mui-selected': {
                      backgroundColor: '#EC895A',
                      color: '#fff',
                    },
                  }}
                  count={totalPages}
                  page={page}
                  onChange={handleChange}
                />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};
export default ProductEngine;
