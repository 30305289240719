import { Autocomplete, Box, Button, Card, CardContent, Chip, Divider, InputLabel, Snackbar, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IoMdArrowBack } from 'react-icons/io';
import CommonInput from '../utils/CommonInput';
import { useEffect, useRef, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import CloseIcon from '@mui/icons-material/Close';
import CreateProductIcon from '../../images/icons/createProductIcon';
import ProductServiceIcon from '../../images/icons/productServiceIcon';
import LanguageSelector from '../utils/LanguageSelector';
import { ProductList, Stages, ServiceList } from '../utils/CommonList';
import { SupplierMapStatus } from '../../constants/common.constants';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupplierProduct } from '../../features/supplierProductSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptId, encryptId } from '../utils/encryptDecrypt';
import PATH from '../utils/path';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';

const AddProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  // const { supplierId, productId } = useParams();
  const params = useParams();
  console.log(params,"$$$$$$$$$$");
  
  let page = params?.page;
  const supplierId = decryptId(params?.supplierId);
  const productId = decryptId(params?.productId);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [errors, setErrors] = useState({ stage: '', title: '', vanityName: '', sku: '' });
  const [imageError, setImageError] = useState('');
  const [productFormData, setProductFormData] = useState({
    vanityName: '',
    stage: '',
    sku: '',
    title: '',
    product: '',
    services: '',
    description: '',
    name: '',
    images: [],
    productType: '',
  });
  const [lang, setLang] = useState('en');
  const [supplierProductService, setSupplierProductService] = useState('PRODUCT');
  const [imageUrl, setImageUrl] = useState([]);
  const { productData } = useSelector((state) => state.product);

  useEffect(() => {
    if (supplierId && productId) {
      const getSupplierProductById = async (index, id) => {
        try {
          const response = await axios.get(`${RestUrlsConstants.supplier}/${supplierId}/product/${productId}`, {
            headers: {
              Authorization: localStorage.getItem('Authorization'),
              'Content-Type': 'application/json',
            },
          });
          if (response?.data?.status) {
            setImageUrl(response?.data?.data?.urls ?? []);
            setSupplierProductService(response?.data?.data?.type);
            setProductFormData((prevData) => ({
              ...prevData,
              stage: response?.data?.data?.stage ?? '',
              sku: response?.data?.data?.skuId ?? '',
              title: response?.data?.data?.title ?? '',
              productType: response?.data?.data?.name,
            }));
          }
        } catch (error) {
          console.log(error?.response?.data?.message);
        }
      };
      getSupplierProductById();
    }
  }, [productId, productData]);

  useEffect(() => {
    if (supplierId && productId) {
      const getSupplierProductById = async (index, id) => {
        try {
          const response = await axios.get(`${RestUrlsConstants.supplier}/${supplierId}/product/${productId}`, {
            headers: {
              Authorization: localStorage.getItem('Authorization'),
              'Content-Type': 'application/json',
            },
          });
          if (response?.data?.status) {
            setProductFormData((prevData) => ({
              ...prevData,
              vanityName: response?.data?.data?.titleVanityName?.[lang] || response?.data?.data?.titleVanityName?.value,
              name: response?.data?.data?.stageVanityName?.[lang] || response?.data?.data?.stageVanityName?.value,
              description: response?.data?.data?.description?.[lang] !== undefined ? response?.data?.data?.description?.[lang] : "",
            }));
          }
        } catch (error) {
          console.log(error?.response?.data?.message);
        }
      };
      getSupplierProductById();
    }
  }, [productId, lang]);

  useEffect(() => {
    if (!productId) {
      setImageUrl([]);
      setProductFormData({
        vanityName: '',
        stage: '',
        sku: '',
        title: '',
        product: '',
        services: '',
        description: '',
        name: '',
        images: [],
        productType: '',
      });
    }
  }, [productId]);

  const handleFileInput = (e) => {
    const newFiles = Array.from(e.target.files);
    const allFiles = [...newFiles];
    const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
    let validFiles = [];
    let invalidFiles = [];

    Array.from(allFiles).forEach((file) => {
      if (supportedFormats.includes(file.type)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });
    if (invalidFiles.length > 0) {
      setImageError('Some files are not supported. Please upload jpg, png, or jpeg files only.');
    } else {
      setImageError('');
    }
    if (validFiles.length > 0) {
      setProductFormData((prevFormData) => ({
        ...prevFormData,
        images: allFiles,
      }));
      const imageUrlUpdate = newFiles.map((file) => URL.createObjectURL(file));
      if (productId) {
        setImageUrl([...imageUrl, ...imageUrlUpdate]);
      } else {
        setImageUrl((prevImageUrl) => [...prevImageUrl, ...imageUrlUpdate]);
      }
    }
  };

  const removeImage = (index, isUploaded, id) => {
    if (isUploaded) {
      handleRemoveImage(index, id);
    } else {
      const updatedFiles = imageUrl.filter((_, i) => i !== index);
      setImageUrl(updatedFiles);
    }
  };
  const handleRemoveImage = async (index, id) => {
    if (!checkOnlineStatus()) return;
    try {
      const payload = {
        image: id,
      };
      const response = await axios.delete(`${RestUrlsConstants.supplier}/${supplierId}/product/${productId}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
        data: payload,
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        const updatedFiles = imageUrl.filter((_, i) => i !== index);
        setImageUrl(updatedFiles);
        dispatch(fetchSupplierProduct(supplierId));
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const validateForm = () => {
    const newErrors = {
      title: '',
      stage: '',
      vanityName: '',
      sku: '',
    };
    let isValid = true;

    if (!productFormData?.title?.trim()) {
      newErrors.title = t('title_is_required');
      isValid = false;
    }
    if (!productFormData?.stage?.trim()) {
      newErrors.stage = t('stage_is_required');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const createSupplierProduct = async () => {
    if (!validateForm()) return;
    try {
      const formData = new FormData();
      if (productFormData.vanityName) {
        formData.append('titleVanityName', productFormData.vanityName);
      }
      formData.append('title', productFormData.title);
      formData.append('stage', productFormData.stage);
      formData.append('name', productFormData.productType);
      if (productFormData.sku) {
        formData.append('skuId', productFormData.sku);
      }
      formData.append('lang', lang);
      formData.append('type', supplierProductService);

      if (productFormData.name) formData.append('stageVanityName', productFormData.name);
      if (productFormData.description) formData.append('description', productFormData.description);

      if (productFormData?.images?.length) {
        for (let i = 0; i < productFormData?.images?.length; i++) {
          formData.append('images', productFormData.images[i]);
        }
      }
      if (!checkOnlineStatus()) return;
      const response = await httpService.post(`${RestUrlsConstants.supplier}/${supplierId}/product`, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        setProductFormData({});
        dispatch(fetchSupplierProduct({ supplierId, page }));
        navigate(`${PATH.ADDSUPPLIER}/${encryptId(supplierId)}/${page}/${params?.chainPage}`);
        setImageUrl([]);
        setProductFormData({
          vanityName: '',
          stage: '',
          sku: '',
          title: '',
          product: '',
          name: '',
          description: '',
          images: [],
          productType: '',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };
  const updateSupplierProduct = async () => {
    if (!validateForm()) return;
    try {
      const formData = new FormData();
      formData.append('titleVanityName', productFormData.vanityName);
      formData.append('title', productFormData.title);
      formData.append('stage', productFormData.stage);
      formData.append('skuId', productFormData.sku);
      formData.append('name', productFormData.productType);
      formData.append('lang', lang);
      formData.append('type', supplierProductService);
      formData.append('stageVanityName', productFormData.name);
      formData.append('description', productFormData.description);
      if (productFormData?.images?.length) {
        for (let i = 0; i < productFormData?.images?.length; i++) {
          formData.append('images', productFormData.images[i]);
        }
      }
      if (!checkOnlineStatus()) return;
      const response = await httpService.patch(`${RestUrlsConstants.supplier}/${supplierId}/product/${productId}`, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        setProductFormData({});
        dispatch(fetchSupplierProduct({ supplierId, page }));
        setImageUrl([]);
        navigate(`${PATH.ADDSUPPLIER}/${encryptId(supplierId)}/${page}/${params?.chainPage}`);
        setProductFormData({
          vanityName: '',
          stage: '',
          sku: '',
          title: '',
          product: '',
          name: '',
          description: '',
          images: [],
          productType: '',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleAdditionalInfo = () => {
    setShowAdditionalInfo(!showAdditionalInfo);
  };
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const handleCloseForm = () => {
    navigate(`${PATH.ADDSUPPLIER}/${encryptId(supplierId)}/${params?.page}/${params?.chainPage}`);
    setImageUrl([]);
    setProductFormData({
      vanityName: '',
      stage: '',
      sku: '',
      title: '',
      product: '',
      services: '',
      description: '',
      name: '',
      images: [],
      productType: '',
    });
  };

  const handleProductType = (t) => {
    setSupplierProductService(t);
    if (productId === ':productId') {
      setProductFormData({
        vanityName: '',
        stage: '',
        sku: '',
        title: '',
        product: '',
        services: '',
        description: '',
        name: '',
        images: [],
        productType: '',
      });
    }
  };
  return (
    <>
      <Button
        startIcon={<IoMdArrowBack />}
        onClick={handleCloseForm}
        sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2, mb: 2 }}
      >
        {t('back')}
      </Button>
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ color: '#0e1090', fontWeight: 'bold' }}>
              {!productId ? t('add_new') : t('edit_product')}
            </Typography>
            <LanguageSelector setLang={setLang} selectLang={lang} />
          </Box>
          <Divider sx={{ width: '100%', background: '#101010', mt: 2 }} />
          <Box sx={{ display: 'flex', gap: '20px', mt: 2 }}>
            <Chip
              onClick={() => handleProductType('PRODUCT')}
              label={t('create_product')}
              icon={<CreateProductIcon color={'white'} />}
              sx={{ bgcolor: supplierProductService === SupplierMapStatus.product ? '#0e1090' : 'gray', color: '#fff', px: 2 }}
              disabled={productId && supplierProductService === SupplierMapStatus.service}
            />
            <Chip
              onClick={() => handleProductType('SERVICE')}
              label={t('create_service')}
              icon={<ProductServiceIcon color={'white'} />}
              sx={{ bgcolor: supplierProductService === SupplierMapStatus.service ? '#0e1090' : 'gray', color: '#fff', px: 2 }}
              disabled={productId && supplierProductService === SupplierMapStatus.product}
            />
          </Box>
          <Box>
            {supplierProductService === SupplierMapStatus.product ? (
              <Autocomplete
                sx={{ mt: 4 }}
                fullWidth
                size="small"
                disablePortal
                id="combo-box-demo"
                options={ProductList}
                value={productFormData.productType}
                onChange={(event, newValue) => {
                  setProductFormData({ ...productFormData, productType: newValue });
                }}
                renderInput={(params) => <TextField {...params} placeholder={t('search_products')} variant="standard" />}
              />
            ) : (
              <Autocomplete
                sx={{ mt: 4 }}
                fullWidth
                size="small"
                disablePortal
                id="combo-box-demo"
                options={ServiceList}
                value={productFormData.productType}
                onChange={(event, newValue) => {
                  setProductFormData({ ...productFormData, productType: newValue });
                }}
                renderInput={(params) => <TextField {...params} placeholder={t('search_services')} variant="standard" />}
              />
            )}

            <CommonInput
              label={t('title') + '*'}
              type="text"
              value={productFormData.title}
              handleChange={(e) => setProductFormData({ ...productFormData, title: e.target.value })}
              placeholder={t('title')}
              variant={'standard'}
              error={!!errors.title}
              helperText={errors.title}
            />
            <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer', mt: 4 }}>{t('stage') + '*'}</InputLabel>
            <Autocomplete
              fullWidth
              size="small"
              disablePortal
              id="combo-box-demo"
              options={Stages}
              value={productFormData.stage}
              onChange={(event, newValue) => {
                setProductFormData({ ...productFormData, stage: newValue });
              }}
              renderInput={(params) => <TextField {...params} placeholder={t('stage')} variant="standard" error={!!errors.stage} helperText={errors.stage} />}
            />

            <CommonInput
              label={t('vanity_title')}
              type="text"
              value={productFormData.vanityName}
              handleChange={(e) => setProductFormData({ ...productFormData, vanityName: e.target.value })}
              placeholder={t('vanity_title')}
              variant={'standard'}
            />

            <CommonInput
              label={t('sku')}
              type="text"
              value={productFormData.sku}
              handleChange={(e) => setProductFormData({ ...productFormData, sku: e.target.value })}
              placeholder={t('sku')}
              variant={'standard'}
            />
            <Typography onClick={() => handleAdditionalInfo()} mt={4} sx={{ color: '#0e1090', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', width: '150px' }}>
              {t('additional_settings')} {showAdditionalInfo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Typography>
            {showAdditionalInfo && (
              <Box>
                <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer', mt: 1 }}>{t('stage_vanity_name')}</InputLabel>
                <TextField
                  fullWidth
                  value={productFormData.name}
                  onChange={(e) => setProductFormData({ ...productFormData, name: e.target.value })}
                  type="text"
                  placeholder={t('stage_vanity_name')}
                  variant={'standard'}
                />

                <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer', mt: 2 }}>{t('description')}</InputLabel>
                <TextField
                  fullWidth
                  value={productFormData.description}
                  onChange={(e) => setProductFormData({ ...productFormData, description: e.target.value })}
                  type="text"
                  placeholder={t('description')}
                  variant={'standard'}
                />
                <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer', mt: 4 }}>{t('upload_images')}</InputLabel>

                <input ref={fileInputRef} required multiple type="file" hidden onChange={handleFileInput} />
                <Button
                  sx={{
                    mt: 2,
                    height: '150px',
                    width: '100%',
                    color: 'black',
                    boxShadow: 'none',
                    border: '2px dashed gray',
                    background: `#eeeeee`,
                    textTransform: 'none',
                    fontWeight: 'bold',
                    ':hover': { background: '#eeeeee', boxShadow: 'none' },
                  }}
                  variant="contained"
                  onClick={() => fileInputRef.current.click()}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                    <Box sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                      <CloudUploadIcon sx={{ color: 'gray', fontSize: '40px' }} />
                      {t('choose_file')}
                      <span style={{ fontSize: '12px', fontWeight: 'normal' }}>{t('image_type')} </span>
                    </Box>
                  </Typography>
                </Button>
                {imageError && <p style={{ color: 'red', fontSize: '12px' }}>{imageError}</p>}
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                  {imageUrl?.map((url, index) => (
                    <Box
                      key={index}
                      sx={{
                        height: '80px',
                        width: '80px',
                        borderRadius: '8px',
                        mt: 2,
                        position: 'relative',
                      }}
                    >
                      {!url?.url && <img src={url} alt="" height="100px" width="80px" style={{ borderRadius: '8px', border: '1px solid gray' }} />}
                      {url?.url && <img src={url?.url} alt="" height="100px" width="80px" style={{ borderRadius: '8px', border: '1px solid gray' }} />}
                      <CloseIcon
                        onClick={(e) => {
                          removeImage(index, url?.url?.startsWith('https'), url?.id);
                        }}
                        sx={{
                          cursor: 'pointer',
                          fontSize: '16px',
                          position: 'absolute',
                          top: '-5px',
                          right: '-5px',
                          color: 'red',
                          zIndex: 200,
                          background: '#fff',
                          borderRadius: '5px',
                          border: '.5px solid gray',
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ boxShadow: 'none', mt: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ color: '#0e1090', fontWeight: 'bold' }}>
            {t('add_new_product_or_service')}
          </Typography>
          <Typography sx={{ color: 'gray', fontSize: '12px' }}>{t('select_the_values_you_want_to_claim_to_easily_aggregate_your_data_in_the_section_below_updates_automatically')}</Typography>
          <Button
            onClick={!productId ? createSupplierProduct : updateSupplierProduct}
            sx={{ bgcolor: '#0e1090', color: '#fff', ':hover': { bgcolor: '#0e1090' }, borderRadius: 6, textTransform: 'capitalize', px: 2, mt: 2 }}
          >
            {t('submit')}
          </Button>
        </CardContent>
      </Card>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};
export default AddProduct;
